import React, { useState, useEffect } from "react";
import { firestore } from "../../Firebase";
import { auth } from "../../Firebase";
import { collection, addDoc, doc, setDoc, getDocs } from "firebase/firestore";
import { useNavigate, Redirect, redirect } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function MotherInfo() {
    // const [motherId, setMotherId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [challengeAtBirth, setChallengeAtBirth] = useState("");
  const [childrenNumber, setChildrenNumber] = useState("");
  const [aliveChildren, setAliveChildren] = useState("");

  const [regions, setRegions] = useState([]);
  const [loading, setLoading] = useState(false);



  useEffect(() => {
    const fetchRegions = async () => {
      const regionsCollection = collection(firestore, "region");
      const regionSnapshot = await getDocs(regionsCollection);
      const regionData = regionSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRegions(regionData);
    };

    fetchRegions();
  }, []);

  const handleRegionChange = (e) => {
    setSelectedRegion(e.target.value);
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    (async () => {
      try {
        // Get the currently logged-in user's UID
        //   const userId = auth.currentUser.uid;

        const userID = auth.currentUser.uid;
        const phone = auth.currentUser.phoneNumber;

        const motherData = {
          user_id: userID,
          child_id: userID,
          mother_id: userID,
          children_number: childrenNumber,
          alive_children: aliveChildren,
          first_name: firstName,
          last_name: lastName,
          phone:phone,
          birth_date: birthDate,
          birth_place: selectedRegion,
          challenge_at_birth: challengeAtBirth,
        };

        const mothersCollectionRef = collection(firestore, "mothers");

        // Add the mother record to the "mothers" collection
        const motherDocRef = await addDoc(mothersCollectionRef, motherData);
        setLoading(true);
        toast.info("Hifadhi inaendelea...", { autoClose: false });
    
        console.log("Mother record added successfully with ID: ", motherDocRef.id);
        setLoading(false);
        toast.success("Hifadhi imekamilika!");
        setTimeout(() => {
          toast.dismiss();
          window.location.href = "https://admin.smartgrowthdiary.co.tz/offline/"; // Replace with your desired redirect URL
        }, 2000); // Wait for 2 seconds before redirecting
      } catch (error) {
        setLoading(false);
        toast.error("Kuna kosa wakati wa kuongeza rekodi ya mama.");
        console.error("Error adding mother record: ", error);
      }
    })();
  };

  return (
    <div
      className="flex flex-col md:justify-center sm:justify-start h-screen overflow-y-auto"
      style={{ backgroundColor: "rgb(10, 172, 223)" }}
    >
      <form onSubmit={handleUpdate}>
      <h1 className="text-2xl mt-2 my-6 text-white font-bold">
        Jaza Taarifa za Mzazi
      </h1>
        {/* name divs */}
        <div className="flex flex-wrap justify-center">
        <div className="flex flex-1 sm:flex-initial flex-col md:max-w-1/2 mx-5">
          <label
            htmlFor="firstName "
            className=" text-white"
            >
            Jina La kwanza:
          </label>
          <input
            type="text"
            id="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
            className="mx-1 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-center"
            />
            
           
          <label
            htmlFor="lastName"
            className=" text-white "
          >
            Jina la Ukoo:
          </label>
          <input
            type="text"
            id="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className="mx-1 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-center"
            required
          />
          

        {/* Birth Date */}
       
          <label className="text-white" htmlFor="birthDate">
            Tarehe Ya Kuzaliwa:
          </label>
          <input
            type="date"
            id="birthDate"
            value={birthDate}
            onChange={(e) => setBirthDate(e.target.value)}
            required
            className="mx-auto py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-center"
          />
        
        {/* Place of Birth */}
         {/* Birth Place */}
        
          <label className="text-white" htmlFor="birthPlace">
            Mahali ya Kuzaliwa:
          </label>
          <select
            id="birthPlace"
            value={selectedRegion}
            onChange={handleRegionChange}
            required
            className="mx-1 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-center"
          >
            <option value="">Chagua Mkoa...</option>
            {regions.map((region) => (
              <option key={region.region_id} value={region.region_id}>
                {region.region_name}
              </option>
            ))}
          </select>
        </div>
        
        {/* Children info */}

        <div className="flex flex-1 sm:flex-initial flex-col md:max-w-1/2 mx-5">
          <label
            htmlFor="firstName "
            className=" text-white"
          >
            Idadi ya Watoto:
          </label>
          <input
            type="text"
            id="firstName"
            value={childrenNumber}
            onChange={(e) => setChildrenNumber(e.target.value)}
            required
            className="mx-1 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-center"
          />
          <label
            htmlFor="lastName"
            className=" text-white "
          >
            Watoto walio Hai:
          </label>
          <input
            type="text"
            id="lastName"
            value={aliveChildren}
            onChange={(e) => setAliveChildren(e.target.value)}
            className="mx-1 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-center"
            required
          />
        
        
        
        
          <label
            htmlFor="medicalChallengeAtBirth"
            className=" text-white my-3"
          >
            Tatizo Lolote Wakati Kuzaliwa:
          </label>
          <textarea
            id="medicalChallengeAtBirth"
            value={challengeAtBirth}
            onChange={(e) => setChallengeAtBirth(e.target.value)}
            required
            className="mx-1 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-center"
          />
        </div>

        </div>

        <button
          className="w-auto my-5 px-10 py-2 bg-blue-500 text-white rounded"
          type="submit"
        >
           {loading ? "Hifadhi..." : "Hifadhi"}
        </button>
      </form>
       {/* Conditionally render the loading spinner */}
       {loading && <div className="spinner" />}
       {/* Toast container */}
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      
    </div>

  );
}

export default MotherInfo;
