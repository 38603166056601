import React, { useState } from 'react';
import { auth } from '../../Firebase'; // Import your Firebase instance
import { updatePassword } from 'firebase/auth';
import { collection, doc, setDoc } from 'firebase/firestore';
import { firestore } from '../../Firebase'; // Import your Firestore instance
import { useNavigate } from 'react-router-dom';
import md5 from 'md5';
import logo_img from "../../images/Smart.png"
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { useHistory } from 'react-router-dom';

function SetPassword({ phoneNumber }) {
    const navigate = useNavigate();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
//   const [isPasswordUpdated, setIsPasswordUpdated] = useState(false); // New state for screen state


  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password === confirmPassword) {

        const encryptedPassword = md5(password);

      // Update password using Firebase Auth
      updatePassword(auth.currentUser, encryptedPassword)
        .then(() => {
          // Password updated successfully
          const userData = {
            password: encryptedPassword,
            user_name: phoneNumber,
            last_Login: new Date(), // Current timestamp
            user_id: auth.currentUser.uid,
            user_privilege: 'level_5',
            role: 'child',
            user_status: 'ACTIVE',
          };

          // Save user record in Firestore
          setDoc(doc(firestore, 'users', auth.currentUser.uid), userData)
            .then(() => {
              console.log('The record has been saved');
              toast.success("Password Set!");
        setTimeout(() => {
          toast.dismiss();
          navigate('/update-info'); // Navigate to the update-info page
        }, 2000);

              
            //   history.push('/UpdateInfo'); 
              // Redirect the user or perform other actions

            })
            .catch((error) => {
              console.log('An Error has occurred while saving to Firestore:', error);
              // Handle Firestore error
            });
        })
        .catch((error) => {
          console.log('An Error has occurred while updating password:', error);
          // Handle password update error
        });
    } else {
      // Handle password mismatch error
    }
  };

  return (
    <div className="flex flex-col justify-center items-center h-screen overflow-auto" style={{ backgroundColor: "rgb(10, 172, 223)" }}>
    <div className="logo">
    <img src={logo_img} className="rounded-full" style={{width:"100px", height:"100px" }} />
  </div>
    <h1 className="text-3xl mt-2 text-white">Set Password</h1>
    <div className="w-full max-w-md p-6 rounded-lg mt-2">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="flex flex-col">
          <label className="text-xl mb-2 text-white" htmlFor="password">
            Password:
          </label>
          <input
            className="border rounded p-2"
            type="password"
            id="password"
            value={password}
            onChange={handlePasswordChange}
            required
          />
        </div>
        <div className="flex flex-col">
          <label className="text-xl mb-2 text-white" htmlFor="confirmPassword">
            Confirm Password:
          </label>
          <input
            className="border rounded p-2"
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            required
          />
        </div>
        <button className="w-full py-2 bg-blue-500 text-white rounded" type="submit">
          Save Password
        </button>
      </form>
       {/* Toast container */}
       <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  </div>
  
  );
}

export default SetPassword;
