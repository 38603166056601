import React, { useState } from "react";
import { auth,firestore } from "../../Firebase";
import {
  collection,
  addDoc,
  doc,
  query,
  where,
  setDoc,
  getDocs,
} from "firebase/firestore";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import SetPassword from "./SetPassword"
import logo_img from "../../images/Smart.png"
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Login() {
  const [phone, setPhone] = useState("+255");
  const [expandForm, setExpandForm] = useState("");
  const [expandPass, setExpandPass] = useState("");
  const [OTP, setOTP] = useState("");

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
      },
      auth
    );
  };

  const requestOTP = async (e) => {
    e.preventDefault();
  
    if (phone.length >= 11) {
      try {
        generateRecaptcha(); // Call your function to generate reCAPTCHA token
        const appVerifier = window.recaptchaVerifier; // Use your existing reCAPTCHA verifier instance
  
        const usersCollection = collection(firestore, 'users');
        const q = query(usersCollection, where('user_name', '==', phone));
        const userQuerySnapshot = await getDocs(q);
  
        if (!userQuerySnapshot.empty) {
          toast.error("User Already exist!");
          // Handle user not found case
        } else {
          signInWithPhoneNumber(auth, phone, appVerifier)
            .then((confirmationResult) => {
              window.confirmationResult = confirmationResult;
              setExpandForm(true); // Assuming you have defined setExpandForm somewhere
            })
            .catch((error) => {
              console.log(error);
            });
          
        }
      } catch (error) {
        console.log("Error checking user:", error);
      }
    }
  };

  const verifyOTP = (e) => {
    let otp = e.target.value;
    setOTP(otp);

    if (otp.length === 6) {
      //Verify OTP
      let confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(otp)
        .then((result) => {
          // User signed in successfully.
          const user = result.user;
          console.log("success");
          setExpandPass(true);
          // ...
        })
        .catch((error) => {
          // User couldn't sign in (bad verification code?)
          // ...
          // console.log("shida");
        });
    }
  };
  if (expandPass) {
    return <SetPassword phoneNumber={phone} />;
  }

  return (
    <div
      className="flex flex-col justify-center items-center h-screen"
      style={{ backgroundColor: "rgb(10, 172, 223)" }}
    >
       <ToastContainer />
        <div className="logo">
    <img src={logo_img} className="rounded-full" style={{width:"100px", height:"100px" }} />
  </div>
         
      <h1 className="text-3xl mt-2 text-white">Register</h1>
      <div className="w-full max-w-md p-6  rounded-lg  mt-2">
        <form onSubmit={requestOTP} className="space-y-4">
          <div className="flex flex-col">
            <label className="text-xl mb-2 text-white" htmlFor="phone">
              Phone Number:
            </label>
            <input
              className="border rounded p-2"
              type="tel"
              id="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </div>
          {expandForm && (

        <div className="flex flex-col">
          <label className="text-green-300">OTP HAS BEEN SENT!!!</label>
          <label className="text-xl mb-2 text-white" htmlFor="otpInput">
            OTP:
          </label>
          <input
            className="border rounded p-2"
            type="number"
            id="otpInput"
            value={OTP}
            onChange={verifyOTP}
          />
        </div>
      )}
      <button className="w-full py-2 bg-blue-500 text-white rounded" type="submit">
        {expandForm ? "Verify OTP" : "Request OTP"}
      </button>
      <div id="recaptcha-container"></div>
    </form>
  </div>
</div>
  );
}
export default Login;
