import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes  } from 'react-router-dom';
import Login from './components/auth/Login';
import SetPassword from './components/auth/SetPassword';
import UpdateInfo from './components/auth/UpdateInfo';
import { auth } from './Firebase'; // Make sure this is imported before any components that use it
import { firestore } from './Firebase'; // Make sure this is imported before any components that use it

import { initializeApp } from 'firebase/app';
import ForgotPassword from './components/auth/ForgotPassword';
import NewPass from './components/auth/NewPass';
import MotherInfo from './components/auth/MotherInfo';

function App() {
  return (
    <div className="App">
     <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/set-password" element={<SetPassword />} />
        <Route path="/update-info" element={<UpdateInfo />} />
        <Route path="/ForgotPassword" element={<ForgotPassword />} />
        <Route path="/NewPass" element={<NewPass />} />
        <Route path="/MotherInfo" element={<MotherInfo />}/>
      </Routes>
    </Router>
    </div>
  );
}

export default App;