import React, { useState, useEffect } from "react";
import { firestore } from "../../Firebase";
import { auth } from "../../Firebase";
import {
  collection,
  addDoc,
  doc,
  query,
  where,
  setDoc,
  getDocs,
} from "firebase/firestore";
import { useNavigate, Redirect, redirect } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function UpdateInfo() {
  const [childId, setChildId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [careGiver, setCareGiver] = useState("");
  const [medicalChallengeAtBirth, setMedicalChallengeAtBirth] = useState("");
  const [challengeAtBirth, setChallengeAtBirth] = useState("");
  const [birthPlace, setBirthPlace] = useState("");
  const [healthworkerId, setHealthworkerId] = useState("");
  const [motherId, setMotherId] = useState("");
  const [recordingTime, setRecordingTime] = useState("");
  const [settlement, setSettlement] = useState("");
  const [sex, setSex] = useState("");
  // const [twinSibling, setTwinSibling] = useState("");
  // const [underweightSibling, setUnderweightSibling] = useState("");
  const [weightAtBirth, setWeightAtBirth] = useState("");
  const [regions, setRegions] = useState([]);
  const [hospital, setHospital] = useState([]);
  const [selectedHospital, setSelectedHospital] = useState("");
  const [loading, setLoading] = useState(false);
  const [districts, setDistricts] = useState([]);
  const [settlements, setSettlements] = useState([]);
  const targetRegionId = "SbJSGW0MD4ZpRasGdWv6";

  const navigate = useNavigate();
  useEffect(() => {
    const fetchRegions = async () => {
      const regionsCollection = collection(firestore, "region");
      const regionSnapshot = await getDocs(regionsCollection);
      const regionData = regionSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRegions(regionData);
    };

    fetchRegions();
  }, []);

  useEffect(() => {
    const fetchSettlements = async () => {
      try {
        // const db = getFirestore();
        const regionRef = doc(firestore, "region", `${targetRegionId}`);
        const districtCollection = collection(regionRef, "district");
        const districtSnapshot = await getDocs(districtCollection);
        const settlementsData = districtSnapshot.docs.map((districtDoc) => ({
          id: districtDoc.id,
          ...districtDoc.data(),
        }));
        setSettlements(settlementsData);
        
      } catch (error) {
        console.error("Error fetching settlements: ", error);
      }
    };

    fetchSettlements();
  }, [targetRegionId]);

  useEffect(() => {
    const fetchHospital = async () => {
      const hospitalCollection = collection(firestore, "hospitals");
      const hospitalSnapshot = await getDocs(hospitalCollection);
      const hospitalData = hospitalSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setHospital(hospitalData);
    };

    fetchHospital();
  }, []);

  const handleRegionChange = (e) => {
    setSelectedRegion(e.target.value);
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    (async () => {
      try {
        // Get the currently logged-in user's UID
        //   const userId = auth.currentUser.uid;

        const userID = auth.currentUser.uid;

        const childData = {
          user_id: userID,
          child_id: userID,
          mother_id: userID,
          first_name: firstName,
          middle_name: middleName,
          last_name: lastName,
          birth_date: birthDate,
          birth_place: selectedRegion,
          care_giver: careGiver,
          challenge_at_birth: challengeAtBirth,
          healthworker_id: "null",
          medical_challenge_at_birth: medicalChallengeAtBirth,
          recording_time: recordingTime,
          settlement: settlement,
          sex: sex,
          // twin_sibling: twinSibling,
          // underweight_sibling: underweightSibling,
          weight_at_birth: weightAtBirth,
          hospital: selectedHospital,
        };

        const userDocRef = doc(firestore, "users", userID);

        // Add the child record to the subcollection under the user's document
        const childDocRef = await addDoc(
          collection(userDocRef, "children"),
          childData
        );
        setLoading(true);
        toast.info("Hifadhi inaendelea...", { autoClose: false });

        console.log(
          "Child record added successfully with ID: ",
          childDocRef.id
        );
        setLoading(false);
        toast.success("Hifadhi imekamilika!, Jaza Taarifa za Mlezi");
        setTimeout(() => {
          toast.dismiss();
          navigate("/MotherInfo");
          // window.location.href = "http://localhost:3001";
        }, 2000); // Wait for 2 seconds before redirecting
        // Optionally update the user's document to track the child's reference
        // await setDoc(userDocRef, { last_child_added: childDocRef });

        // Reset form fields if needed
      } catch (error) {
        setLoading(false);
        toast.error("Kuna kosa wakati wa kuongeza rekodi ya mtoto.");
        console.error("Error adding child record: ", error);
      }
    })();
  };

  return (
    <div
      className="flex flex-col justify-start md:h-screen overflow-y-auto h-[100%]"
      style={{ backgroundColor: "rgb(10, 172, 223)" }}
    >
        <h1 className=" text-2xl my-5 text-white font-bold text-center">
          Jaza Taarifa za Mtoto
        </h1>
      <form onSubmit={handleUpdate} className="overflow-hidden" >
        {/* First Name */}
        <div className="flex flex-wrap justify-center">
        <div className="flex flex-1 sm:flex-initial flex-col md:max-w-1/2 mx-5">
          <label className="text-white" htmlFor="firstName">
            Jina La kwanza:
          </label>
          <input
            type="text"
            id="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
            className="mx-1 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-center"
          />
        
        {/* Middle Name */}
       
          <label className="text-white" htmlFor="middleName">
            Jina La Kati:
          </label>
          <input
            type="text"
            id="middleName"
            value={middleName}
            onChange={(e) => setMiddleName(e.target.value)}
            required
            className="mx-1 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-center"
          />
        
        {/* Last Name */}
        
          <label className="text-white" htmlFor="lastName">
            Jina la Ukoo:
          </label>
          <input
            type="text"
            id="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
            className="mx-1 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-center"
          />
        
        {/* Sex */}
       
          <label className="text-white" htmlFor="sex">
            Jinsia:
          </label>
          <div className="flex mx-auto space-x-4">
            <label className="text-white">
              <input
                type="radio"
                id="sex-m"
                name="sex"
                value="M"
                checked={sex === "M"}
                onChange={(e) => setSex(e.target.value)}
                className="mr-2"
              />
              Mme
            </label>
            <label className="text-white">
              <input
                type="radio"
                id="sex-f"
                name="sex"
                value="F"
                checked={sex === "F"}
                onChange={(e) => setSex(e.target.value)}
                className="mr-2"
              />
              Mke
            </label>
          </div>
        
        {/* Birth Date */}
       
          <label className="text-white" htmlFor="birthDate">
            Tarehe Ya Kuzaliwa:
          </label>
          <input
            type="date"
            id="birthDate"
            value={birthDate}
            onChange={(e) => setBirthDate(e.target.value)}
            required
            className="py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-center"
          />
        
        {/* Birth Place */}
       
          <label className="text-white" htmlFor="birthPlace">
            Mahali ya Kuzaliwa:
          </label>
          <select
            id="birthPlace"
            value={selectedRegion}
            onChange={handleRegionChange}
            required
            className="mx-1 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-center"
          >
            <option value="">Chagua Mkoa...</option>
            {regions.map((region) => (
              <option key={region.region_id} value={region.region_id}>
                {region.region_name}
              </option>
            ))}
          </select>
        </div>
        {/* Weight at Birth */}
        <div className="flex flex-1 sm:flex-initial flex-col md:max-w-[50%] mx-5">
          <label className="text-white" htmlFor="weightAtBirth">
            Uzito wa kuzaliwa:
          </label>
          <input
            type="text"
            id="weightAtBirth"
            value={weightAtBirth}
            onChange={(e) => setWeightAtBirth(e.target.value)}
            required
            className="mx-1 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-center"
          />
        
        {/* Settlement */}
        
          <label className="text-white" htmlFor="settlement">
            Makazi ya sasa:
          </label>
          <select
            id="settlement"
            value={settlement}
            onChange={(e) => setSettlement(e.target.value)}
            required
            className="mx-1 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-center"
          >
            <option value="">Chagua Makazi...</option>
            {settlements.map((settlement) => (
              <option key={settlement.id} value={settlement.district_id}>
                {settlement.district_name}
              </option>
            ))}
          </select>
        
        {/* Hospital */}
        
          <label className="text-white" htmlFor="settlement">
            Hospitali ya Karibu:
          </label>
          <select
            id="settlement"
            value={selectedHospital}
            onChange={(e) => setSelectedHospital(e.target.value)}
            required
            className="mx-1 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-center"
          >
            <option value="">Chagua Hospital ya Karibu...</option>
            {hospital.map((hospital) => (
              <option key={hospital.hospital_id} value={hospital.hospital_id}>
                {hospital.hospital_name}
              </option>
            ))}
          </select>
        
        {/* Care Giver */}

      
          <label className="text-white my-3" htmlFor="careGiver">
            Muangalizi wa Mtoto:
          </label>
          <div className="flex mx-auto space-x-4">
            <label className="text-white">
              <input
                type="radio"
                id="careGiver-mother"
                name="careGiver"
                value="Mother"
                checked={careGiver === "Mother"}
                onChange={(e) => setCareGiver(e.target.value)}
                className="mr-2"
              />
              Mama
            </label>
            <label className="text-white">
              <input
                type="radio"
                id="careGiver-father"
                name="careGiver"
                value="Father"
                checked={careGiver === "Father"}
                onChange={(e) => setCareGiver(e.target.value)}
                className="mr-2"
              />
              Baba
            </label>
            <label className="text-white">
              <input
                type="radio"
                id="careGiver-guardian"
                name="careGiver"
                value="Guardian"
                checked={careGiver === "Guardian"}
                onChange={(e) => setCareGiver(e.target.value)}
                className="mr-2"
              />
              Mlezi
            </label>
          </div>
        
        {/* Medical Challenge */}
          <label className="text-white" htmlFor="medicalChallengeAtBirth">
            Tatizo Lolote Wakati Kuzaliwa:
          </label>
          <textarea
            id="medicalChallengeAtBirth"
            value={medicalChallengeAtBirth}
            onChange={(e) => setMedicalChallengeAtBirth(e.target.value)}
            required
            className="mx-1 py-1 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500 text-center"
          />
        </div>  
        </div>
        <button
          className="w-auto my-5 px-10 py-2 bg-blue-500 text-white rounded"
          type="submit"
        >
          {loading ? "Hifadhi..." : "Hifadhi"}
        </button>
      </form>
      {/* Conditionally render the loading spinner */}
      {loading && <div className="spinner" />}
      {/* Toast container */}
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default UpdateInfo;
